import { Outlet, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Layout = () => {
  const location = useLocation();
  // console.log(location.pathname);
  return (
    <>
      <nav>
        <div className="navigation">
          <span>
            <Link to="/">Seungyong Moon</Link>
          </span>
          <span>
            {location.pathname === "/" ? (
              <Link to="/info">Info</Link>
            ) : (
              <Link to="/">Close</Link>
            )}
          </span>
        </div>
      </nav>

      <Outlet />
    </>
  );
};

export default Layout;
